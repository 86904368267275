/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import Screens from "../cta/Screens";
import logo_img from "../../assets/images/logo.png";

function Navbar({explore}) {
  const [ctaVisibility , setCtaVisibility] = useState(true)
    return ( 
        <>
            {/* {ctaVisibility && <Screens setCrumb={() => setCtaVisibility(!ctaVisibility)} />} */}
             <div className="Navbar">
                <a href="/">
                  <img src={logo_img} alt="logo" />
                  <span>Xcelsz</span>
                </a>
                <div className="links">
                  <a
                    href="#"
                    // href="mailto:info@xcelsz.com&subject=Explore%20Solutions%20with%20Xcelsz"
                    // target="_blank"
                    onClick={() => {explore( (prev) => !prev)
                      }
                    }
                  >
                    Explore Solutions
                  </a>
                </div>
              </div>
        </>
     );
}

export default Navbar;
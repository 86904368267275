import '../features/giveFeedback.css'
import emailjs from 'emailjs-com'

import { useState, useRef,useEffect } from 'react';
import Alert from './Alert';
import Alertyes from './Alertyes';
import AlertConst from './AlertConst';

export default function GiveFeedback(props){
    const [form1, setForm1] =useState(true)
    const [form2, setForm2] =useState(false)
    const [form3, setForm3] =useState(false)
    const [form4, setForm4] =useState(false)
    const [form5, setForm5] =useState(false)
    const [isOpen, setOpen] = useState(false)
    const [isYes, setYes] = useState(false)
    const [isConst, setConst] = useState(false)


   


    const [formdata1, setdata1] =useState({
       name : 'Authority Site',
       rating : '',
       likes : '',
       improve: ''
    })
    const [formdata2, setdata2] =useState({
       name : 'Agile Solutions',
       rating : '',
       likes : '',
       improve: ''
    })
    const [formdata3, setdata3] =useState({
       name : 'Property',
       rating : '',
       likes : '',
       improve: ''
    })
    const [formdata4, setdata4] =useState({
       name : 'Marketing',
       rating : '',
       likes : '',
       improve: ''
    })
    const [formdata5, setdata5] =useState({
       name : 'New Features',
       improve: ''
    })

    function submitForm(){
     
       if(form1){
        if((formdata1['rating'] === '' || formdata1.likes === '' || formdata1.rating === '' ) ){
          console.log('not filled')
          setConst(prev => !prev)
        } else{
        emailjs.send(
          'service_5qz8da6',
          'template_1aekag6',
          formdata1,
          'QmnLvOQrVWGFPHqsF')
        .then(function() {
        
          
            setYes(prev => !prev);
          setdata1(prev => {
            return {
              ...prev,
              rating : '',
              likes : '',
              improve: ''
            }

          })
          
          
         
        }, function(error) {
          console.log('Failed to send email:', error);
          // alert('An error occurred while sending your message. Please check your internet and try again later.');
          setOpen(prev => !prev)   
      })
        }}
      else if(form2){
        if((formdata2.rating === '' || formdata2.likes === '' || formdata2.rating === '' ) ){
          setConst(prev => !prev)
        }else{
        emailjs.send(
          'service_5qz8da6',
          'template_1aekag6',
          formdata2,
          'QmnLvOQrVWGFPHqsF')
        .then(function() {
         
          
            setYes(prev => !prev);
          setdata2(prev => {
            return {
              ...prev,
              rating : '',
              likes : '',
              improve: ''
            }

          })
          
          
         
        }, function(error) {
          console.log('Failed to send email:', error);
          // alert('An error occurred while sending your message. Please check your internet and try again later.');
          setOpen(prev => !prev)   
      })
    }}
      else if(form3){
        if((formdata3.rating === '' || formdata3.likes === '' || formdata3.rating === '' ) ){
          setConst(prev => !prev)
        }
        else{
        emailjs.send(
          'service_5qz8da6',
          'template_1aekag6',
          formdata3,
          'QmnLvOQrVWGFPHqsF')
        .then(function() {
         
          
            setYes(prev => !prev);
          setdata3(prev => {
            return {
              ...prev,
              rating : '',
              likes : '',
              improve: ''
            }

          })
          
          
         
        }, function(error) {
          console.log('Failed to send email:', error);
          // alert('An error occurred while sending your message. Please check your internet and try again later.');
          setOpen(prev => !prev)   
      })
    }}
      else if(form4){
        if((formdata4.rating === '' || formdata4.likes === '' || formdata4.rating === '' ) ){
          setConst(prev => !prev)
        }else{
        emailjs.send(
          'service_5qz8da6',
          'template_1aekag6',
          formdata4,
          'QmnLvOQrVWGFPHqsF')
        .then(function() {
         
          
            setYes(prev => !prev);
            setdata4(prev => {
              return {
                ...prev,
                rating : '',
                likes : '',
                improve: ''
              }
            
            })
          
         
         
        }, function(error) {
          console.log('Failed to send email:', error);
          // alert('An error occurred while sending your message. Please check your internet and try again later.');
          setOpen(prev => !prev)   
      })
    }}
      else if(form5){
        if( formdata5.improve === ''  ){
          setConst(prev => !prev)
        }else{
        emailjs.send(
          'service_5qz8da6',
          'template_1aekag6',
          formdata5,
          'QmnLvOQrVWGFPHqsF')
        .then(function() {
         
          
            setYes(prev => !prev);
          setdata5(prev => {
            return {
              ...prev,
              rating : '',
              likes : '',
              improve: ''
            }

          })
          
          
         
        }, function(error) {
          console.log('Failed to send email:', error);
          // alert('An error occurred while sending your message. Please check your internet and try again later.');
          setOpen(prev => !prev)   
      })
    }}
  }
      
      
    
    
    

    function handleform1(e){
      setdata1((prev)=>{
        return {
          ...prev,
          [e.target.id]: e.target.value
        }
      })

    }
    function handleform2(e){
      setdata2((prev)=>{
        return {
          ...prev,
          [e.target.id]: e.target.value
        }
      })

    }
    function handleform3(e){
      setdata3((prev)=>{
        return {
          ...prev,
          [e.target.id]: e.target.value
        }
      })

    }
    function handleform4(e){
      setdata4((prev)=>{
        return {
          ...prev,
          [e.target.id]: e.target.value
        }
      })

    }
    function handleform5(e){
      setdata5((prev)=>{
        return {
          ...prev,
          [e.target.id]: e.target.value
        }
      })

    }
   

   
   
    
    useEffect(() => {
        function handleClickOutside(event) {
          if (event.target.className === 'modal-main') {
         
            props.setBread()
          
          }
    
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);


    return (
        <section className='modal-main'>
            <div className="modal-content">
              <div className='modal-heading nonscaling'>
                <h2>We value your Feedback</h2>
                <p className='modal-bold'>Please take some few minutes to complete the form below</p>
                <p className='modal-not-bold'>Your response will not be shared with anyone </p>
                <p className='modal-yellow'></p>
                <span className='clsBtn' onClick={()=>{ props.setBread()}}>x</span>
              </div>
            <div className='content-box'>
             <div className='side-bar'>
                <div className={`clickable ${form1?'dark':''}`}  onClick={ () => {
                    setForm1(prev => !prev)
                    setForm2(false)
                    setForm4(false)
                    setForm3(false)
                    setForm5(false)
                }
                    
                    }>
                    <p>1. Xcelsz.com Site<span className='less1'>&gt;</span></p>
                </div>
                <Alert  isOpen={isOpen} setOpen={setOpen}/>
                <Alertyes isYes={isYes} setYes ={setYes} />
                <AlertConst isConst={isConst} setConst ={setConst} />
                {form1 &&<div className='form1-mobile'>
                 <form>
                   
                   <div className='form1'>
                    <label htmlFor='scale'>a. On a scale of 1 to 10 how likely would you recommend xcelsz.com to others?<br></br></label>
                    <input className='form-input-mobile' placeholder='Enter Number' type='number' max='10' min='1' id='rating' value={formdata1.rating} onChange={(e) => handleform1(e)}/>
                   </div>
                   <div className='form1'>
                    <label htmlFor='text'>b. What do you like most about this website?<br></br></label>
                    <textarea className='form-text-mobile' placeholder='Enter Message' id='likes' value={formdata1.likes} onChange={(e) => handleform1(e)}/>
                   </div>
                   <div className='form1'>
                    <label htmlFor='improve'>c. What can we improve about our website?<br></br></label>
                    <textarea className='form-text-mobile' placeholder='Enter Message' id='improve' value={formdata1.improve} onChange={(e) => handleform1(e)}/>
                   </div>

                </form>
                </div>}
                <div className={`clickable ${form2?'dark':''}`}onClick={ () => {
                    setForm2(prev => !prev)
                    setForm1(false)
                    setForm3(false)
                    setForm4(false)
                    setForm5(false)
                }
                    
                    }>
                    <p>2. Xcelsz Solutions Platforms<span className='less2'>&gt;</span></p>
                </div>
                {form2 && <div className='form1-mobile'>
                <form>
                   
                   <div className='form1'>
                    <label htmlFor='scale'>a. On a scale of 1 to 10 how likely would you recommend Xcelsz' solutions  to others?<br></br></label>
                    <input className='form-input-mobile' placeholder='Enter Number' type='number' max='10' min='1' id="rating" value={formdata2.rating} onChange={(e) => handleform2(e)}/>
                   </div>
                   <div className='form1'>
                    <label htmlFor='text'>b. What do you like most about Xcelzs Solutions?<br></br></label>
                    <textarea className='form-text-mobile' placeholder='Enter Message' id="likes" value={formdata2.likes} onChange={(e) => handleform2(e)}/>
                   </div>
                   <div className='form1'>
                    <label htmlFor='improve'>c. What can we do to enhance your experience of using Xcelsz Solutions?<br></br></label>
                    <textarea className='form-text-mobile' placeholder='Enter Message' id="improve" value={formdata2.improve} onChange={(e) => handleform2(e)}/>
                   </div>

                </form>
               
                </div>}

               

                <div className={`clickable ${form5?'dark':''}`} onClick={ () => {
                    setForm5(prev => !prev)
                    setForm2(false)
                    setForm3(false)
                    setForm4(false)
                    setForm1(false)
                }
                    
                    }>
                    <p>3. New Feature<span className='less5'>&gt;</span></p>
                </div>
                {form5 && <div className='form1-mobile except'>
                 <form>
                   
                  
                   <div className='form1'>
                    <label htmlFor='text' 
                    style={{
                      width: '240px'
                    }}
                    >a. What feature would you like to see on this website and on Xcelsz solutions sub sites?<br></br></label>
                    <textarea className='form-text-mobile' placeholder='Enter Message' id="improve" value={formdata5.improve} onChange={(e) => handleform5(e)}/>
                  
                   </div>
                  

                </form>
               
                </div>}
             </div>
             <div className='content'>
               { form1 && <form>
                   
                   <div className='form1'>
                    <label htmlFor='scale'>a. On a scale of 1 to 10 how likely would you recommend xcelsz.com to others?<br></br></label>
                    <input className='form-input' placeholder='Enter Number' type='number' max='10' min='1' id='rating' value={formdata1.rating} onChange={(e) => handleform1(e)}/>
                   </div>
                   <div className='form1'>
                    <label htmlFor='text'>b. What do you like most about this website?<br></br></label>
                    <textarea className='form-text' placeholder='Enter Message' id='likes' value={formdata1.likes} onChange={(e) => handleform1(e)}/>
                   </div>
                   <div className='form1'>
                    <label htmlFor='improve'>c. What can we improve about website?<br></br></label>
                    <textarea className='form-text' placeholder='Enter Message' id='improve' value={formdata1.improve} onChange={(e) => handleform1(e)}/>
                   </div>

                </form>
               }
               { form2 && <form>
                   
                   <div className='form1'>
                    <label htmlFor='scale'>a. On a scale of 1 to 10 how likely would you recommend Xcelsz' solutions  to others?<br></br></label>
                    <input className='form-input' placeholder='Enter Number' type='number' max='10' min='1' id="rating" value={formdata2.rating} onChange={(e) => handleform2(e)}/>
                   </div>
                   <div className='form1'>
                    <label htmlFor='text'>b. What do you like most about Xcelzs Solutions?<br></br></label>
                    <textarea className='form-text' placeholder='Enter Message' id="likes" value={formdata2.likes} onChange={(e) => handleform2(e)}/>
                   </div>
                   <div className='form1'>
                    <label htmlFor='improve'>c. Can we do to enhance your experience of using Xcelsz Solutions?<br></br></label>
                    <textarea className='form-text' placeholder='Enter Message' id="improve" value={formdata2.improve} onChange={(e) => handleform2(e)}/>
                   </div>

                </form>
               }
               { form3 && <form>
                   
                   <div className='form1'>
                    <label htmlFor='scale'>a. On a scale of 1 to 10 how likely would you recommend Xcelsz Property Solutions (XPS) to others?<br></br></label>
                    <input className='form-input' placeholder='Enter Number' type='number' max='10' min='1' id="rating" value={formdata3.rating} onChange={(e) => handleform3(e)}/>
                   </div>
                   <div className='form1'>
                    <label htmlFor='text'>b. What do you like most about XPS?<br></br></label>
                    <textarea className='form-text' placeholder='Enter Message'id="likes" value={formdata3.likes} onChange={(e) => handleform3(e)}/>
                   </div>
                   <div className='form1'>
                    <label htmlFor='improve'>c. What can we improve about XPS?<br></br></label>
                    <textarea className='form-text' placeholder='Enter Message' id="improve" value={formdata3.improve } onChange={(e) => handleform3(e)}/>
                   </div>

                </form>
               }
               { form4 && <form>
                   
                   <div className='form1'>
                    <label htmlFor='scale'>a. On a scale of 1 to 10 how likely would you recommend Xcelsz Marketing Solutions (XMS) to others?<br></br></label>
                    <input className='form-input' placeholder='Enter Number' type='number' max='10' min='1' id='rating' value={formdata4.rating } onChange={(e) => handleform4(e)}/>
                   </div>
                   <div className='form1'>
                    <label htmlFor='text'>b. What do you like most about XMS?<br></br></label>
                    <textarea className='form-text' placeholder='Enter Message' id='likes' value={formdata4.likes } onChange={(e) => handleform4(e)}/>
                   </div>
                   <div className='form1'>
                    <label htmlFor='improve'>c. What can we do to delight you on our XMS?<br></br></label>
                    <textarea className='form-text' placeholder='Enter Message' id='improve' value={formdata4.improve } onChange={(e) => handleform4(e)}/>
                   </div>

                </form>
               }
               { form5 && <form>
                   
                  
                   <div className='form1'>
                    <label htmlFor='text'>a. What feature would you like to see on this website and on xcelsz solutions sub sites?<br></br></label>
                    <textarea className='form-text' placeholder='Enter Message' id="improve" value={formdata5.improve} onChange={(e) => handleform5(e)}/>
                  
                   </div>
                  

                </form>
               }
             </div>
             </div>
             <div className='submitBtn' onClick={submitForm}>
               <p> Submit Feedback </p>
             </div>
            </div>
        </section>
    )
}
import React, { useState, useEffect } from 'react';
import '../cta/screens.css'

import close_img from "../../assets/images/x-regular-24.png";

const AllCards = [
  {
    header: 'Maximise measurable Value Faster with Oish',
    timer: ['12','08','10','02'],
    button: 'Join Oish Waitlist'
  },
  {
    header: 'Learn from your Peers with iXcelsz',
    timer: ['12','08','10','02'],
    button: 'Join UYATMR Waitlist'
  },
  {
    header: 'Leverage on your network for commission payment with iMarketer',
    timer: ['12','08','10','02'],
    button: 'Join iMarketer Waitlist'
  },
  {
    header: 'Scale your income with qualified Leads with iPartner',
    timer: ['12','08','10','02'],
    button: 'Join iPartner Waitlist'
  },
  {
    header: 'Make Problem Properties work for you with TPV',
    timer: ['12','08','10','02'],
    button: 'Join TPV Waitlist'
  },
  // {
  //   header: 'Occupy Dream Short-Lets easily with LocalStayz',
  //   timer: ['12','08','10','02'],
  //   button: 'Join LocalStayz Waitlist'
  // },
  {
    header: 'Maximise measurable Value Faster with Oish',
    timer: ['12','08','10','02'],
    button: 'Join Oish Waitlist'
  },
  {
    header: 'Learn from your Peers with iXcelsz',
    timer: ['12','08','10','02'],
    button: 'Join iXcelsz Waitlist'
  },
  {
    header: 'Leverage on your network for commission payment with iMarketer',
    timer: ['12','08','10','02'],
    button: 'Join iMarketer Waitlist'
  },
  {
    header: 'Scale your income with qualified Leads with iPartner',
    timer: ['12','08','10','02'],
    button: 'Join iPartner Waitlist'
  },
  {
    header: 'Make Problem Properties work for you with TPV',
    timer: ['12','08','10','02'],
    button: 'Join TPV Waitlist'
  },
  {
    header: 'Occupy Unique Stays easily with LocalStayz',
    timer: ['12','08','10','02'],
    button: 'Join LocalStayz Waitlist'
  },
  {
    header: 'Find Best Match and outsource easily with iPPM',
    timer: ['12','08','10','02'],
    button: 'Join Oish Waitlist'
  },
  {
    header: 'Get into property based on what you can afford with iPP',
    timer: ['12','08','10','02'],
    button: 'Join iPP Waitlist'
  },
  {
    header: 'Occupy Dream Homes Small Small with GyeFie',
    timer: ['12','08','10','02'],
    button: 'Join GyeFie Waitlist'
  },
  {
    header: 'Explore, Learn and succeed with iNetwork',
    timer: ['12','08','10','02'],
    button: 'Join iNetwork Waitlist'
  }
] 

const IndividualCard = [
  {
    header: 'Occupy Dream Homes Small Small with GyeFie',
    timer: ['12','08','10','02'],
    button: 'Join GyeFie Waitlist'
  },
  {
    header: 'Explore, Learn and succeed with iNetwork',
    timer: ['12','08','10','02'],
    button: 'Join iNetwork Waitlist'
  },
  {
    header: 'Get into property based on what you can afford with iPP',
    timer: ['12','08','10','02'],
    button: 'Join iPP Waitlist'
  },
  {
    header: 'Make Problem Properties work for you with TPV',
    timer: ['12','08','10','02'],
    button: 'Join TPV Waitlist'
  },
  {
    header: 'Occupy Dream Short-Lets easily with LocalStayz',
    timer: ['12','08','10','02'],
    button: 'Join LocalStayz Waitlist'
  },
  {
    header: 'Find Best Match and outsource easily with iPPM',
    timer: ['12','08','10','02'],
    button: 'Join Oish Waitlist'
  },
  {
    header: 'Learn from your Peers with iXcelsz',
    timer: ['12','08','10','02'],
    button: 'Join UYATMR Waitlist'
  },
  {
    header: 'Leverage on your network for commission payment with iMarketer',
    timer: ['12','08','10','02'],
    button: 'Join iMarketer Waitlist'
  },
  {
    header: 'Learn from your Peers with iXcelsz',
    timer: ['12','08','10','02'],
    button: 'Join UYATMR Waitlist'
  },
]
const BusinessCard = [
  {
    header: 'Scale your income with qualified Leads with iPartner',
    timer: ['12','08','10','02'],
    button: 'Join iPartner Waitlist'
  },
  {
    header: 'Maximise measurable Value Faster with Oish',
    timer: ['12','08','10','02'],
    button: 'Join Oish Waitlist'
  },
  {
    header: 'Get into property based on what you can afford with iPP',
    timer: ['12','08','10','02'],
    button: 'Join iPP Waitlist'
  },
  {
    header: 'Make Problem Properties work for you with TPV',
    timer: ['12','08','10','02'],
    button: 'Join TPV Waitlist'
  },
  {
    header: 'Occupy Dream Short-Lets easily with LocalStayz',
    timer: ['12','08','10','02'],
    button: 'Join LocalStayz Waitlist'
  },
  {
    header: 'Find Best Match and outsource easily with iPPM',
    timer: ['12','08','10','02'],
    button: 'Join Oish Waitlist'
  },
]
const HDTCard = [
  {
    header: 'Maximise measurable Value Faster with Oish',
    timer: ['12','08','10','02'],
    button: 'Join Oish Waitlist'
  },
  {
    header: 'Learn from your Peers with iXcelsz',
    timer: ['12','08','10','02'],
    button: 'Join UYATMR Waitlist'
  },
  {
    header: 'Find Best Match and outsource easily with iPPM',
    timer: ['12','08','10','02'],
    button: 'Join Oish Waitlist'
  },
  {
    header: 'Make Problem Properties work for you with TPV',
    timer: ['12','08','10','02'],
    button: 'Join TPV Waitlist'
  },
  {
    header: 'Occupy Dream Short-Lets easily with LocalStayz',
    timer: ['12','08','10','02'],
    button: 'Join LocalStayz Waitlist'
  },
  {
    header: 'Leverage on your network for commission payment with iMarketer',
    timer: ['12','08','10','02'],
    button: 'Join iMarketer Waitlist'
  },
]
const DFUCard = [
  {
    header: 'Find Best Match and outsource easily with iPPM',
    timer: ['12','08','10','02'],
    button: 'Join Oish Waitlist'
  },
  {
    header: 'Scale your income with qualified Leads with iPartner',
    timer: ['12','08','10','02'],
    button: 'Join iPartner Waitlist'
  },
  {
    header: 'Maximise measurable Value Faster with Oish',
    timer: ['12','08','10','02'],
    button: 'Join Oish Waitlist'
  }
]
const DWUCard = [
  {
    header: 'Get into property based on what you can afford with iPP',
    timer: ['12','08','10','02'],
    button: 'Join iPP Waitlist'
  },
  {
    header: 'Maximise measurable Value Faster with Oish',
    timer: ['12','08','10','02'],
    button: 'Join Oish Waitlist'
  },
]
const ASCard = [
  {
    header: 'Maximise measurable Value Faster with Oish',
    timer: ['12','08','10','02'],
    button: 'Join Oish Waitlist'
  },
  {
    header: 'Learn from your Peers with iXcelsz',
    timer: ['12','08','10','02'],
    button: 'Join UYATMR Waitlist'
  },
]
const DSCard = [
  {
    header: 'Explore, Learn and succeed with iNetwork',
    timer: ['12','08','10','02'],
    button: 'Join iNetwork Waitlist'
  },
  {
    header: 'Scale your income with qualified Leads with iPartner',
    timer: ['12','08','10','02'],
    button: 'Join iPartner Waitlist'
  },
  {
    header: 'Leverage on your network for commission payment with iMarketer',
    timer: ['12','08','10','02'],
    button: 'Join iMarketer Waitlist'
  },

]
const PSCard = [
  {
    header: 'Occupy Dream Homes Small Small with GyeFie',
    timer: ['12','08','10','02'],
    button: 'Join GyeFie Waitlist'
  },
  {
    header: 'Occupy Unique Stays easily with LocalStayz',
    timer: ['12','08','10','02'],
    button: 'Join LocalStayz Waitlist'
  },
  {
    header: 'Make Problem Properties work for you with TPV',
    timer: ['12','08','10','02'],
    button: 'Join TPV Waitlist'
  },
  {
    header: 'Maximise measurable Value Faster with Oish',
    timer: ['12','08','10','02'],
    button: 'Join Oish Waitlist'
  }
]


function calculateTimeLeft() {
  const year = new Date().getFullYear();
  const difference = +new Date(`2024-08-1`) - +new Date();

 
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    };
  }
  return timeLeft;
}

function Screens({setCrumb, openwaitlist}) {

  const[cards, setCards] = useState(AllCards)

  const [visibleCard, setVisibleCard] = useState(6);
  const [sliceStart, setSliceStart] = useState(0)
  const [slicePrev, setSlicePrev] = useState(0)

  const [roleFilter, setRoleFilter] = useState(false)
  const [needFilter, setNeedFilter] = useState(false)
  const [solutionFilter, setSolutionFilter] = useState(false)


    function closeModal() {
        console.log("Form closed");
        setCrumb();
      }

    const loadMore = () => {
      
      setSliceStart(visibleCard)
      setVisibleCard(visibleCard + 6);
      setSlicePrev(visibleCard)
    };

    const loadPrev = () => {
      setSliceStart(slicePrev - 6)
      setVisibleCard(slicePrev);
      setSlicePrev(slicePrev - 6)
      console.log(slicePrev);
    };


    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
      const id = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
  
      return () => {
        clearTimeout(id);
      };
    }); 


    function filter(event) {
      let value = event.target.value
      if (value == 'Individual') {
        setCards(IndividualCard)
        setNeedFilter(true)
        setSolutionFilter(true)
      }else if (value == 'Business') {
        setCards(BusinessCard)
        setNeedFilter(true)
        setSolutionFilter(true)
      }else if (value == 'HYDT') {
        setCards(HDTCard)
        setRoleFilter(true)
        setSolutionFilter(true)
      }else if (value == 'DIFU') {
        setCards(DFUCard)
        setRoleFilter(true)
        setSolutionFilter(true)
      }else if (value == 'DIWU') {
        setCards(DWUCard)
        setRoleFilter(true)
        setSolutionFilter(true)
      }else if (value == 'AS') {
        setCards(ASCard)
        setNeedFilter(true)
        setRoleFilter(true)
      }else if (value == 'DS') {
        setCards(DSCard)
        setNeedFilter(true)
        setRoleFilter(true)
      }else if (value == 'PS') {
        setCards(PSCard)
        setNeedFilter(true)
        setRoleFilter(true)
      }else{
        setCards(AllCards)
        setNeedFilter(false)
        setRoleFilter(false)
        setSolutionFilter(false)
      }
    } 

   

   

    return(
    <>
    <div className="ctaScreen-overlay">
      <div className="ctaScreen-container">
        <div className="closeBtn" onClick={closeModal}>
          <a href="#" onClick={closeModal}>
            <img src={close_img} alt="X" />
          </a>
        </div>
        <h1>Promoting Growth & Advancement</h1>
        {/* <p>There is a solution for everyone</p> */}
        <div className="yellow-bar"> </div>
        <span>Access one-stop solution platforms that enables personal, professional, and business transformation. Explore the go-to range of xcelsz solutions that meet Individuals and businesses current need to grow, no mater their situation.</span>        
        <div className="cards-container">
            <div className="container-header">
                <p>There is a solution for everyone. What is your Current Need?</p>
                <div className="filter-selectors">
                    <div className='all-solutions'>All solutions</div>
                    <select className='filter-options' onChange={(e)=>filter(e)} disabled={roleFilter}>
                        <option value="">By Role</option>
                        <option value="Individual">Individual</option>
                        <option 
                        value="Business">Business</option>
                    </select>
                    <select className='filter-options' onChange={(e)=>filter(e)} disabled={needFilter}>
                        <option value="">By Need</option>
                        <option value="HYDT">Help you do it</option>
                        <option value="DIFU">Do it for you</option>
                        <option value="DIWU">Do it with you</option>
                    </select>
                    <select className='filter-options'onChange={(e)=>filter(e)} disabled={solutionFilter}>
                        <option value="">By Solution Type</option>
                        <option value="AS">Agile Solutions</option>
                        <option value="DS">Digital Solutions</option>
                        <option value="PS">Property Solutions</option>
                    </select>
                </div>
            </div>
            <div className="project-cards">
            {cards.slice(sliceStart, visibleCard).map((data,index)=>{
                return(
                <div className="card0">
                  <div className="caption">
                    <p>{data.header}</p>
                  </div>
                  <div className="mid">
                    <p>Coming Soon</p>
                    <div className="countdown-row">
                      <div className="countdown-col">
                        <h3>Days</h3>
                        <span id="yatmrDay">{timeLeft.days}</span>
                      </div>
                      <div className="countdown-col">
                        <h3>Hrs</h3>
                        <span id="yatmrHr">{timeLeft.hours}</span>
                      </div>
                      <div className="countdown-col">
                        <h3>Min</h3>
                        <span id="yatmrMin">{timeLeft.minutes}</span>
                      </div>
                      <div className="countdown-col">
                        <h3>Sec</h3>
                        <span id="yatmrSec">{timeLeft.seconds}</span>
                      </div>
                    </div>
                  </div>
                  <div className="foot">
                    <a href="#"                 
                    className="details"
                    onClick={() => {
                      setCrumb( (prev) => !prev)
                      openwaitlist((prev) => !prev)
                      }
                    }
                      >
                      <span>{data.button}</span>
                    </a>
                  </div>
                </div>
              )
            })}
              {/* {cards.map((data,index)=>{
                  if (index <= 5) {
                    return(
                      <div className="card0">
                        <div className="caption">
                          <p>{data.header}</p>
                        </div>
                        <div className="mid">
                          <p>Coming Soon</p>
                          <div className="countdown-row">
                            <div className="countdown-col">
                              <h3>Days</h3>
                              <span id="yatmrDay">{timeLeft.days}</span>
                            </div>
                            <div className="countdown-col">
                              <h3>Hrs</h3>
                              <span id="yatmrHr">{timeLeft.hours}</span>
                            </div>
                            <div className="countdown-col">
                              <h3>Min</h3>
                              <span id="yatmrMin">{timeLeft.minutes}</span>
                            </div>
                            <div className="countdown-col">
                              <h3>Sec</h3>
                              <span id="yatmrSec">{timeLeft.seconds}</span>
                            </div>
                          </div>
                        </div>
                        <div className="foot">
                          <a href="#"                 
                          className="details"
                          onClick={() => {
                            setCrumb( (prev) => !prev)
                            openwaitlist((prev) => !prev)
                            }
                          }
                            >
                            <span>{data.button}</span>
                          </a>
                        </div>
                      </div>
                    )
                  }
              })} */}
              
            </div>
            <div className="pagination">
                <div className="pag-btn">
                  {/* <span className='prev'> {'<'} </span> */}
                  {slicePrev != 0 && (
                    <span className='prev' onClick={loadPrev}>{'<'}</span>
                  )}
                  {visibleCard < cards.length && (
                    <span className='next' onClick={loadMore}>{'>'}</span>
                  )}
                  {/* <span className='next'> {'>'} </span> */}
                </div>
            </div>
           
        </div>
      </div>
    </div>
    
    </>
    )
}

export default Screens;
import { useEffect,useState } from "react"
import "../FindOutMore/FindOutMore.css"
import img2 from "../../../assets/images/findOutMore.png"

export default function FindOutMore (props){
const [ accordFind1, setAccrordFind1]  = useState(false)
const [ accordFind2, setAccrordFind2]  = useState(false)
const [ accordFind3, setAccrordFind3]  = useState(false)
const [ accordFind4, setAccrordFind4]  = useState(false)
const [ accordFind5, setAccrordFind5]  = useState(false)
const [ accordFind6, setAccrordFind6]  = useState(false)
const [ accordFind7, setAccrordFind7]  = useState(false)
const [ accordFind8, setAccrordFind8]  = useState(false)
const [ accordFind9, setAccrordFind9]  = useState(false)
const [ accordFind10, setAccrordFind10]  = useState(false)

function handleclosefind(){
    props.setFind(prev => !prev)
}




function handleAccord1(){
    setAccrordFind1(!accordFind1)
    setAccrordFind2(false)
    setAccrordFind3(false)
    setAccrordFind4(false)
    setAccrordFind5(false)
    setAccrordFind6(false)
    setAccrordFind7(false)
    setAccrordFind8(false)
    setAccrordFind9(false)
    setAccrordFind10(false)
}
function handleAccord2(){
    setAccrordFind2(!accordFind2)
    setAccrordFind1(false)
    setAccrordFind3(false)
    setAccrordFind4(false)
    setAccrordFind5(false)
    setAccrordFind6(false)
    setAccrordFind7(false)
    setAccrordFind8(false)
    setAccrordFind9(false)
    setAccrordFind10(false)
}
function handleAccord3(){
    setAccrordFind3(!accordFind3)
    setAccrordFind1(false)
    setAccrordFind2(false)
    setAccrordFind4(false)
    setAccrordFind5(false)
    setAccrordFind6(false)
    setAccrordFind7(false)
    setAccrordFind8(false)
    setAccrordFind9(false)
    setAccrordFind10(false)
}
function handleAccord4(){
    setAccrordFind4(!accordFind4)
    setAccrordFind1(false)
    setAccrordFind3(false)
    setAccrordFind2(false)
    setAccrordFind5(false)
    setAccrordFind6(false)
    setAccrordFind7(false)
    setAccrordFind8(false)
    setAccrordFind9(false)
    setAccrordFind10(false)
}
function handleAccord5(){
    setAccrordFind5(!accordFind5)
    setAccrordFind1(false)
    setAccrordFind3(false)
    setAccrordFind4(false)
    setAccrordFind2(false)
    setAccrordFind6(false)
    setAccrordFind7(false)
    setAccrordFind8(false)
    setAccrordFind9(false)
    setAccrordFind10(false)
}
function handleAccord6(){
    setAccrordFind6(!accordFind6)
    setAccrordFind1(false)
    setAccrordFind3(false)
    setAccrordFind4(false)
    setAccrordFind5(false)
    setAccrordFind2(false)
    setAccrordFind7(false)
    setAccrordFind8(false)
    setAccrordFind9(false)
    setAccrordFind10(false)
}
function handleAccord7(){
    setAccrordFind7(!accordFind7)
    setAccrordFind1(false)
    setAccrordFind3(false)
    setAccrordFind4(false)
    setAccrordFind5(false)
    setAccrordFind6(false)
    setAccrordFind2(false)
    setAccrordFind8(false)
    setAccrordFind9(false)
    setAccrordFind10(false)
}
function handleAccord8(){
    setAccrordFind8(!accordFind8)
    setAccrordFind1(false)
    setAccrordFind3(false)
    setAccrordFind4(false)
    setAccrordFind5(false)
    setAccrordFind6(false)
    setAccrordFind7(false)
    setAccrordFind2(false)
    setAccrordFind9(false)
    setAccrordFind10(false)
}
function handleAccord9(){
    setAccrordFind9(!accordFind9)
    setAccrordFind1(false)
    setAccrordFind3(false)
    setAccrordFind4(false)
    setAccrordFind5(false)
    setAccrordFind6(false)
    setAccrordFind7(false)
    setAccrordFind8(false)
    setAccrordFind2(false)
    setAccrordFind10(false)
}
function handleAccord10(){
    setAccrordFind10(!accordFind10)
    setAccrordFind1(false)
    setAccrordFind3(false)
    setAccrordFind4(false)
    setAccrordFind5(false)
    setAccrordFind6(false)
    setAccrordFind7(false)
    setAccrordFind8(false)
    setAccrordFind9(false)
    setAccrordFind2(false)
}

    return(
        <div className="whyxcelsx-container-find" id="no-close">
            <div className="whyxcelsz-header">
                <p>Find out More about how it works</p>
            </div>
            <div className="whyxcelsz-accordion">
                <div className={`whyxcelsz-accordion1-f ${accordFind1?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">Oish</p>
                        <p onClick={() => handleAccord1()} className="plusOrminus">{accordFind1?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text-f">
                        <h2>Goal</h2>
                        <p className="find-out-p">
                        Oish is to democratise agile adoption, making it possible for businesses of any size to go Agile, ensuring business Agility. 
                        </p>
                        <div className="below-text">
                            
                            <ul>
                                <h2>Whats Included (Key features)</h2>
                                <li>Agile Consultancy solutions</li>
                                <li>Agile Coaching solutions</li>
                                <li>Agile Delivery solutions</li>
                                <li>Agile Metrics reports</li>
                                <li>Oish Dashboard</li>
                                <li>24/7 Support</li>
                            </ul>
                            <img src={img2} alt="xcelzs solution" className="Find-out-img"></img>
                        </div>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1-f ${accordFind2?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">iXcelsz</p>
                        <p onClick={() => handleAccord2()} className="plusOrminus">{accordFind2?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text-f">
                        <h2>Goal</h2>
                        <p className="find-out-p">
                        iXcelsz is to make it easy for Individuals to susceed in their businesses and advance their Careers. 
                        </p>
                        <div className="below-text">
                            
                            <ul>
                                <h2>Whats Included (Key features)</h2>
                                <li>UYAO, UYALR, UYATMR Mentoring</li>
                                <li>UYAO, UYALR, UYATMR Events</li>
                                <li>UYAO, UYALR, UYATMR Metrics</li>
                                <li>Progress Tracking & Reports</li>
                                <li>Oish Dashboard</li>
                                <li>24/7 Support</li>
                            </ul>
                            <img src={img2} alt="xcelzs solution" className="Find-out-img"></img>
                        </div>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1-f ${accordFind3?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">Local Stayz</p>
                        <p onClick={() => handleAccord3()} className="plusOrminus">{accordFind3?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text-f">
                        <h2>Goal</h2>
                        <p className="find-out-p">
                        LocalStayz is to help Individuals and businesses find quality yet affordable unique short stays in Ghana, Scotland and Nigeria and live just like a Local. 
                        </p>
                        <div className="below-text">
                            
                            <ul>
                                <h2>Whats Included (Key features)</h2>
                                <li>Fully Negotiated Short Stays</li>
                                <li>Booking Progress tracking</li>
                                <li>Fully Qualified Guests</li>
                                <li>Booking progress report</li>
                                <li>LocalStayz Dashboard</li>
                                <li>24/7 Support</li>
                            </ul>
                            <img src={img2} alt="xcelzs solution" className="Find-out-img"></img>
                        </div>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1-f ${accordFind4?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">iPropertyPartner (iPP)</p>
                        <p onClick={() => handleAccord4()} className="plusOrminus">{accordFind4?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text-f">
                        <h2>Goal</h2>
                        <p className="find-out-p">
                        iPP is to help Individuals and businesses to do property JVs from anywhere in the world.
                        </p>
                        <div className="below-text">
                            
                            <ul>
                                <h2>Whats Included (Key features)</h2>
                                <li>Fully Negotiated JV projects</li>
                                <li>Tailored JV Match</li>
                                <li>Progress Tracking</li>
                                <li>Progress report</li>
                                <li>iPP Dashboard</li>
                                <li>24/7 Support</li>
                            </ul>
                            <img src={img2} alt="xcelzs solution" className="Find-out-img"></img>
                        </div>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1-f ${accordFind5?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">iPropertyProjectManager (iPPM)</p>
                        <p onClick={() => handleAccord5()} className="plusOrminus">{accordFind5?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text-f">
                        <h2>Goal</h2>
                        <p className="find-out-p">
                        iPPM is to help Individuals and businesses to occupy and invest in properties easily from anywhere in the world 
                        </p>
                        <div className="below-text">
                            
                            <ul>
                                <h2>Whats Included (Key features)</h2>
                                <li>Fully Negotiated Properties</li>
                                <li>Fully Negotiated Deals</li>
                                <li>Sale Progress tracking</li>
                                <li>Sales Reports</li>
                                <li>iPPM Dashboard</li>
                                <li>24/7 Support</li>
                            </ul>
                            <img src={img2} alt="xcelzs solution" className="Find-out-img"></img>
                        </div>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1-f ${accordFind6?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">The Property Vendee (TPV)</p>
                        <p onClick={() => handleAccord6()} className="plusOrminus">{accordFind6?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text-f">
                        <h2>Goal</h2>
                        <p className="find-out-p">
                        TPV is to make it possible for distressed home owners or financial institutions to sell or rent their properties quickly within 21 days.
                        </p>
                        <div className="below-text">
                            
                            <ul>
                                <h2>Whats Included (Key features)</h2>
                                <li>Fully Negotiated BMV Deals</li>
                                <li>Fully Qualified Buyers/Tenants</li>
                                <li>Progress tracking</li>
                                <li>Progress Report</li>
                                <li>TPV Dashboard</li>
                                <li>24/7 Support</li>
                            </ul>
                            <img src={img2} alt="xcelzs solution" className="Find-out-img"></img>
                        </div>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1-f ${accordFind7?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">GyeFie</p>
                        <p onClick={() => handleAccord7()} className="plusOrminus">{accordFind7?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text-f">
                        <h2>Goal</h2>
                        <p className="find-out-p">
                        GyeFie is to make it easy for Property Occupiers to occupy their home on a flexible payment plan.  
                        </p>
                        <div className="below-text">
                            
                            <ul>
                                <h2>Whats Included (Key features)</h2>
                                <li>Fully Negotiated Land Deals</li>
                                <li>Fully Negotiated Off-plan deals</li>
                                <li>Sale progress tracking</li>
                                <li>Sales reports</li>
                                <li>GyeFie Dashboard</li>
                                <li>24/7 Support</li>
                            </ul>
                            <img src={img2} alt="xcelzs solution" className="Find-out-img"></img>
                        </div>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1-f ${accordFind8?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">iMarketer</p>
                        <p onClick={() => handleAccord8()} className="plusOrminus">{accordFind8?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text-f">
                        <h2>Goal</h2>
                        <p className="find-out-p">
                        iMarketer is to enable Individuals to start a side hustle easily. 
                        </p>
                        <div className="below-text">
                            
                            <ul>
                                <h2>Whats Included (Key features)</h2>
                                <li>Affiliate marketing opportunities</li>
                                <li>Unlimited affiliate lead sourcing</li>
                                <li>Commissions progress tracking</li>
                                <li>Commissions progress reports</li>
                                <li>iMarketer Dashboard</li>
                                <li>24/7 Support</li>
                            </ul>
                            <img src={img2} alt="xcelzs solution" className="Find-out-img"></img>
                        </div>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1-f ${accordFind9?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">iPartner</p>
                        <p onClick={() => handleAccord9()} className="plusOrminus">{accordFind9?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text-f">
                        <h2>Goal</h2>
                        <p className="find-out-p">
                        iPartner is to enable Individuals and businesses to scale their business income 10X.  
                        </p>
                        <div className="below-text">
                            
                            <ul>
                                <h2>Whats Included (Key features)</h2>
                                <li>Fully Qualified Leads</li>
                                <li>Fully Negotiated Offers</li>
                                <li>Leads progress tracking</li>
                                <li>Leads progress reports</li>
                                <li>iPartner Dashboard</li>
                                <li>24/7 Support</li>
                            </ul>
                            <img src={img2} alt="xcelzs solution" className="Find-out-img"></img>
                        </div>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1-f ${accordFind10?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">iNetwork</p>
                        <p onClick={() => handleAccord10()} className="plusOrminus">{accordFind10?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text-f">
                        <h2>Goal</h2>
                        <p className="find-out-p">
                        iNetwork is to make it easy for New Starters to embark on their new journeys anywhere in the world, making success possible for all. 
                        </p>
                        <div className="below-text">
                            
                            <ul>
                                <h2>Whats Included (Key features)</h2>
                                <li>AI solutions</li>
                                <li>Matching of like-minds</li>
                                <li>Forums</li>
                                <li>Event Planning</li>
                                <li>iNetwork Dashboard</li>
                                <li>24/7 Support</li>
                            </ul>
                            <img src={img2} alt="xcelzs solution" className="Find-out-img"></img>
                        </div>
                    </div>
                </div>
               
               

            </div>
            <div onClick={()=> handleclosefind() }className="whyxcelsz-close">
                <p>Close</p>
            </div>

        </div>
    )
 }
import { useEffect,useState } from "react"
import "../FAQ/FAQ.css"
import faq from "../../../assets/images/FAQs-cuate 1.png";



export default function FAQ (props){
const [ accord1, setAccord1]  = useState(false)
const [ accord2, setAccord2]  = useState(false)
const [ accord3, setAccord3]  = useState(false)
const [ accord4, setAccord4]  = useState(false)
const [ accord5, setAccord5]  = useState(false)
const [ accord6, setAccord6]  = useState(false)
const [ accord7, setAccord7]  = useState(false)
const [ accord8, setAccord8]  = useState(false)
const [ accord9, setAccord9]  = useState(false)
const [ accord10, setAccord10]  = useState(false)


function handleclose(){
    props.setMyFaq(prev => !prev)
}
useEffect(()=>{
  function handleEvent(e){
    // if (e.target.id !== 'noclose'){
    //     props.setDiscover(prev => !prev)
    // }

  }
 
  document.addEventListener('mousedown',handleEvent);
  
  return () => { 
    document.removeEventListener('mousedown',handleEvent);
}

},[])

function handleAccord1(){
    setAccord1(!accord1)
}
function handleAccord2(){
    setAccord2(!accord2)
}
function handleAccord3(){
    setAccord3(!accord3)
}
function handleAccord4(){
    setAccord4(!accord4)
}
function handleAccord5(){
    setAccord5(!accord5)
}
function handleAccord6(){
    setAccord6(!accord6)
}
function handleAccord7(){
    setAccord7(!accord7)
}
function handleAccord8(){
    setAccord8(!accord8)
}
function handleAccord9(){
    setAccord9(!accord9)
}
function handleAccord10(){
    setAccord10(!accord10)
}



return(
    <div className="faq-wrapper" id="no-close">
        <div className="faq-title">
            <p>Learn more about xcelsz solutions</p>
        </div>
        <div className="faq-accordion">
            <div className={`faq-accordion1 ${accord1?'active':''}`}>
                <div className="faq-accordion1-head">
                    <p className="faq-product-name">Can you opt for more than one xclesz solution?</p>
                    <p onClick={() => handleAccord1()} className="plusOrminus">{accord1?"-":"+"}</p>
                </div>
                
                <div className="faq-accordion-text">
                    <p>Yes , you  can.   Businesses and Individuals can opt for any solution 
                        that met their needs and can subscribe to many solutions at the same time.</p>
                        <img src={faq} alt="xcelzs solution"></img>
                </div>
            </div>
            <div className={`faq-accordion1 ${accord2?'active':''}`}>
                <div className="faq-accordion1-head">
                    <p className="faq-product-name">Who is  Business?</p>
                    <p onClick={() => handleAccord2()} className="plusOrminus">{accord2?"-":"+"}</p>
                </div>
                
                <div className="faq-accordion-text">
                    <p className="accord2">Anyone acting in the capacity of a business in any sector </p>
                    <img src={faq} alt="xcelzs solution"></img>
                </div>
            </div>
            <div className={`faq-accordion1 ${accord3?'active':''}`}>
                <div className="faq-accordion1-head">
                    <p className="faq-product-name">Who is an Individual</p>
                    <p onClick={() => handleAccord3()} className="plusOrminus">{accord3?"-":"+"}</p>
                </div>
                
                <div className="faq-accordion-text">
                    <p>Anyone acting in a private capacity</p>
                    <img src={faq} alt="xcelzs solution"></img>
                </div>
            </div>
            <div className={`faq-accordion1 ${accord4?'active':''}`}>
                <div className="faq-accordion1-head">
                    <p className="faq-product-name">How do I get started with xcelsz solutions?</p>
                    <p onClick={() => handleAccord4()} className="plusOrminus">{accord4?"-":"+"}</p>
                </div>
                
                <div className="faq-accordion-text-ul">
                    <img src={faq} alt="xcelzs solution"></img>
                    <p className="accord4">
                        <ul>
                            <li>Step 1: Subscribe to the preferred solution platform</li>
                            <br/>
                            <li>Step 2: Verify and update your account</li>
                            <br/>
                            <li>Step 3: Access Dashboard and use it</li>
                        </ul>
                    </p>
                </div>
            </div>
            <div className={`faq-accordion1 ${accord5?'active':''}`}>
                <div className="faq-accordion1-head">
                    <p className="faq-product-name">How do I join  the Waitlist?</p>
                    <p onClick={() => handleAccord5()} className="plusOrminus">{accord5?"-":"+"}</p>
                </div>
                
                <div className="faq-accordion-text-ul">
                <img src={faq} alt="xcelzs solution"></img>
                    <p>
                        <ul>
                            <li>Step 1: Click on Join Waitlist CTA button</li>
                        <br/>
                            <li>Step 2: Complete the join Waitlist Form</li>
                        <br/>
                            <li>Step 3: Submit the “join Waitlist” form details</li>
                        </ul>
                    </p>
                </div>
            </div>
            <div className={`faq-accordion1 ${accord6?'active':''}`}>
                <div className="faq-accordion1-head">
                    <p className="faq-product-name">How do I give Feedback?</p>
                    <p onClick={() => handleAccord6()} className="plusOrminus">{accord6?"-":"+"}</p>
                </div>
                
                <div className="faq-accordion-text-ul">
                <img src={faq} alt="xcelzs solution"></img>
                    <p>
                        <ul>
                            <li>Step 1: Click on “Given Feedback” CTA button</li>
                        <br/>
                            <li>Step 2: Complete the”Give Feedback” Form</li>
                        <br/>
                            <li>Step 3: Submit the “Give Feedback” form details</li>
                        </ul>
                    </p>
                </div>
            </div>
            <div className={`faq-accordion1 ${accord7?'active':''}`}>
                <div className="faq-accordion1-head">
                    <p className="faq-product-name">How does Explore Solutions work?</p>
                    <p onClick={() => handleAccord7()} className="plusOrminus">{accord7?"-":"+"}</p>
                </div>
                
                <div className="faq-accordion-text">

                <p>
                    <ul>
                        <li>Step 1: Click on Explore Solutions or explore All solutions</li>
                        <br/>
                        <li>Step 2: Select your  Filters  to highlight your need</li>
                        <br/>
                        <li>Step 3: Browse through the options filtered</li>
                        </ul>
                </p>
                    <img src={faq} alt="xcelzs solution"></img>

                </div>
                </div>
            <div className={`faq-accordion1 ${accord8?'active':''}`}>
                <div className="faq-accordion1-head">
                    <p className="faq-product-name">How do I follow xcelsz solutions?</p>
                    <p onClick={() => handleAccord8()} className="plusOrminus">{accord8?"-":"+"}</p>
                </div>
                
                <div className="faq-accordion-text-ul">
                <img src={faq} alt="xcelzs solution"></img>
                    <p>
                        <ul>
                            <li>Step 1: Click on “Follow us” socials icons</li>
                        <br/>
                            <li>Step 2: Go to Socials</li>
                        <br/>
                            <li>Step 3: Follow Us</li>
                        </ul>
                    </p>
                </div>
            </div>
            <div className={`faq-accordion1 ${accord9?'active':''}`}>
                <div className="faq-accordion1-head">
                    <p className="faq-product-name">What is BCF?</p>
                    <p onClick={() => handleAccord9()} className="plusOrminus">{accord9?"-":"+"}</p>
                </div>
                
                <div className="faq-accordion-text">
                    <p>BCF stands for Building, Construction and Furnishing</p>
                    <img src={faq} alt="xcelzs solution"></img>
                </div>
            </div>
            <div className={`faq-accordion1 ${accord10?'active':''}`}>
                <div className="faq-accordion1-head">
                    <p className="faq-product-name">What is BMV?</p>
                    <p onClick={() => handleAccord10()} className="plusOrminus">{accord10?"-":"+"}</p>
                </div>
                
                <div className="faq-accordion-text">
                    <p>BMV means Below Marketing Value</p>
                    <img src={faq} alt="xcelzs solution"></img>
                </div>
            </div>


            
           

        </div>
        <div onClick={()=> handleclose() }className="faq-close">
            <p>Close</p>
        </div>

    </div>
)
}

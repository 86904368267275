
import alertwaitImg from "../../assets/images/User-alert.png"

export default function Alertyes (props){

  function handleClose(e){
   e.preventDefault()
   props.setAlert(prev => !prev)

   props.setwaitlist((prev)=>{
    return {
      ...prev,
      
        whoAreYou : '',
        name: '',
        productType: '',
        email: '',
        country: '',
        number: ''
     
    }
  })
  }

   return(
       <>
        
         <div
         // className={`wiggle ${props.isYes?'active': ''}`}
         className="alert-waitdiv"
       >
          <div className="alert-waitinfo"
          style={{
           display : 'flex',
           justifyContent: 'center',
           alignItems:'center'
          }}>
           <img src={alertwaitImg} alt="feedback-alert"></img>
           <div className='alert-waittext'>
           <h2
         style={{
           marginBottom:'0.9em',
           fontSize:'1.1em'
         }}>Thank you {props.waitList.name}</h2>
         <p>You have been been added to the {props.waitList.productType} Wait  List</p>

           </div>
        
         </div>
        
         <button
         className="alert-waitbtn"
         onClick={handleClose}
         >X</button>
       </div>
       
           
       
       </>
   )
}
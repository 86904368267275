import location_img from "../../../assets/images/location.png";
import world_img from "../../../assets/images/world.png";
import solution1_img from "../../../assets/images/solution1.png";
import solution2_img from "../../../assets/images/solution2.png";
import solution3_img from "../../../assets/images/solution3.png";
import back_img from "../../../assets/images/Back.png";

import React, { useEffect, useState } from "react";

function ThirdSection() {
  const [slideIndex, setSlideIndex] = useState(1);

  const dataSlider =[
    '5 businesses have subscribed from the UK',
    '10 professionals subscribers in the UK',
    '1 professional subscriber in Finland',
    '3 professionals subscribers in the Netherlands',
    '3 professionals subscribers in Germany',
    '5 professionals subscribers in the US',
    '15 Business subscribers in Ghana',
    '3 Business subscribers in Nigeria'
  ]

  const nextSlide = () => {
    if (slideIndex !== dataSlider.length) {
      setSlideIndex(slideIndex + 1);
      console.log(slideIndex);
    } else if (slideIndex === dataSlider.length) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(dataSlider.length);
    }
  };

  const moveDot = (index) => {
    setSlideIndex(index);
  };

  return (
    <div className="third-section">
      <div className="inner-section">
        <div className="myleft">
          <div className="text-col">
            <p>Enjoy one of a kind</p>
            <h3>simple, digital solutions </h3>
            <p>that guarantee maximum value from anywhere in the world.</p>
          </div>
          {/* <div className="loc">
                <img
                    id="locationTop"
                    src={location_img}
                    alt="Location Pin"
                    style={{ display: "none" }}
                />
                </div> */}
          <div className="carou">
            <a id="prev" className="prev" onClick={prevSlide}>
              <img src={back_img} alt="PREV" />
            </a>
            <a id="next" className="next" onClick={nextSlide}>
              <img src={back_img} alt="NEXT" />
            </a>

            {dataSlider.map((obj, index) => {
              return (
                <div
                  key={obj.id}
                  className={
                    slideIndex === index + 1
                      ? "mycontents"
                      : "mycontents"
                  }
                  style={slideIndex === index + 1?{ display: "flex" }:{}}
                >
                  <p>{obj}</p>
                </div>
              );
            })}
           
          </div>
        </div>
        <div className="myright">
          {/* <div className="points">
            <div className="point1">
              <img
                id="point2"
                src={location_img}
                alt="Pointer"
                style={{ opacity: "0.5" }}
              />
            </div>
            <div className="point2">
              <img
                id="point1"
                src={location_img}
                alt="Pointer"
                style={{ opacity: 1 }}
              />
            </div>
            <div className="point4">
              <img
                id="point3"
                src={location_img}
                alt="Pointer"
                style={{ opacity: "0.5" }}
              />
            </div>
          </div> */}
          <img src={world_img} alt="WORLD LOGO" />
        </div>
      </div>
      <div className="mycomponents">
        <div className="mycompo">
          <div className="imgDiv">
            <img src={solution1_img} alt="IMAGE" />
          </div>
          <strong>300+</strong>
          <span>Users</span>
        </div>
        <div className="mycompo">
          <div className="imgDiv">
            <img src={solution2_img} alt="IMAGE" />
          </div>
          <strong>10+</strong>
          <span>Solutions</span>
        </div>
        <div className="mycompo">
          <div className="imgDiv">
            <img src={solution3_img} alt="IMAGE" />
          </div>
          <strong>11+</strong>
          <span>Countries</span>
        </div>
      </div>
    </div>
  );
}

export default ThirdSection;

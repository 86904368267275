import React, {useEffect, useState} from "react";
import WaitList from "../../../components/features/waitlist";
import Screens from "../../../components/cta/Screens";

function calculateTimeLeft() {
    const year = new Date().getFullYear();
    const difference = +new Date(`2024-08-1`) - +new Date();
  
   
    let timeLeft = {};
  
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
    return timeLeft;
}
  

function DigitalSection(params) {
  const [myCrumb , setMyCrumb] = useState(false)
  const [ctaVisibility , setCtaVisibility] = useState(false)


    //functionality
  //second section functionality

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  }); 



    return(
      <>
      {myCrumb && <WaitList feedback={myCrumb} setCrumb={() => setMyCrumb(!myCrumb)}/>}
      {ctaVisibility && <Screens setCrumb={() => setCtaVisibility(!ctaVisibility)} openwaitlist ={() => setMyCrumb(!myCrumb)}  />}

        <div className="digital-section">
        <div className="layover">
          <div className="mywrapper">
            <div className="wrapper-left">
              <div className="firstElem">Featured</div>
              <div className="parentElem">
                <div className="childf">
                  Grow with our Digital Solutions
                </div>
                {/* <span href="#">Join Oish Waiting List</span> */}
              </div>
            </div>
            {/* <div className="countme">
              <div className="countme-left">Coming Soon</div>
              <div className="countme-right">
                <div className="countdown-col">
                  <h3>Days</h3>
                  <span id="oishDay">-1</span>
                </div>
                <div className="countdown-col">
                  <h3>Hrs</h3>
                  <span id="oishHr">-19</span>
                </div>
                <div className="countdown-col">
                  <h3>Min</h3>
                  <span id="oishMin">-50</span>
                </div>
                <div className="countdown-col">
                  <h3>Sec</h3>
                  <span id="oishSec">-33</span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="main-section">
          {/* <div className="myheader">Grow with our Digital Solutions</div> */}
          <div className="childs">
            <div className="card0">
              <div className="caption">
                <p>Learn from your peers with iXcelsz</p>
              </div>
              <div className="mid">
                <p>Coming Soon</p>
                <div className="countdown-row">
                  <div className="countdown-col">
                    <h3>Days</h3>
                    <span id="yatmrDay">{timeLeft.days}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Hrs</h3>
                    <span id="yatmrHr">{timeLeft.hours}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Min</h3>
                    <span id="yatmrMin">{timeLeft.minutes}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Sec</h3>
                    <span id="yatmrSec">{timeLeft.seconds}</span>
                  </div>
                </div>
              </div>
              <div className="foot">
              <a href="#"                 
                 className="details"
                  onClick={() => {setMyCrumb( (prev) => !prev)
                    console.log(myCrumb,'UYATMR list button clicked')
                    }
                  }
                  >
                  <span>Join iXcelsz Waitlist</span>
                </a>
              </div>
            </div>
            <div className="card0">
              <div className="caption">
                <p>Leverage on your network with iMarketer</p>
              </div>
              <div className="mid">
                <p>Coming Soon</p>
                <div className="countdown-row">
                  <div className="countdown-col">
                    <h3>Days</h3>
                    <span id="yatmrDay">{timeLeft.days}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Hrs</h3>
                    <span id="yatmrHr">{timeLeft.hours}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Min</h3>
                    <span id="yatmrMin">{timeLeft.minutes}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Sec</h3>
                    <span id="yatmrSec">{timeLeft.seconds}</span>
                  </div>
                </div>
              </div>
              <div className="foot">
              <a href="#"
                className="details"
                onClick={() => {setMyCrumb( (prev) => !prev)
                console.log(myCrumb,'iMarketer list button clicked')
                }
              }
                >
                  <span>Join iMarketer Waitlist</span>
                </a>
              </div>
            </div>
            <div className="card0">
              <div className="caption">
                <p>Scale your business income with iPartner</p>
              </div>
              <div className="mid">
                <p>Coming Soon</p>
                <div className="countdown-row">
                  <div className="countdown-col">
                    <h3>Days</h3>
                    <span id="yatmrDay">{timeLeft.days}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Hrs</h3>
                    <span id="yatmrHr">{timeLeft.hours}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Min</h3>
                    <span id="yatmrMin">{timeLeft.minutes}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Sec</h3>
                    <span id="yatmrSec">{timeLeft.seconds}</span>
                  </div>
                </div>
              </div>
              <div className="foot">
              <a href="#"
                  className="details"
                  onClick={() => {setMyCrumb( (prev) => !prev)
                  console.log(myCrumb,'iPartner list button clicked')
                  }
                }
                >
                  <span>Join iPartner Waitlist</span>
                </a>
              </div>
            </div>
            <div className="card0">
              <div className="caption">
                <p>Maximise measurable Value  with Oish</p>
              </div>
              <div className="mid">
                <p>Coming Soon</p>
                <div className="countdown-row">
                  <div className="countdown-col">
                    <h3>Days</h3>
                    <span id="yatmrDay">{timeLeft.days}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Hrs</h3>
                    <span id="yatmrHr">{timeLeft.hours}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Min</h3>
                    <span id="yatmrMin">{timeLeft.minutes}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Sec</h3>
                    <span id="yatmrSec">{timeLeft.seconds}</span>
                  </div>
                </div>
              </div>
              <div className="foot">
              <a href="#"
                  className="details"
                  onClick={() => {setMyCrumb( (prev) => !prev)
                  console.log(myCrumb,'Oish list button clicked')
                  }
                }
                >
                  <span>Join Oish Waitlist</span>
                </a>
              </div>
            </div>
           
          </div>

          <div className="explore-more">
            <a
              href="#"
              // href="mailto:info@xcelsz.com&subject=Explore%20Solutions%20with%20Xcelsz"
              // target="_blank"
              onClick={() => {setCtaVisibility( (prev) => !prev)
                }
              }
            >
              Explore All Solutions
            </a>
          </div>
        </div>
      </div>
      </>
    )
}


export default DigitalSection;
import alertimg from '../../assets/images/Confirmed-alert.png'

export default function Alertyes (props){

    

   function handleClose(e){
    e.preventDefault()
    props.setYes(prev => !prev)
   }

    return(
        <>
         {props.isYes && (
          <div
          // className={`wiggle ${props.isYes?'active': ''}`}
          className="alertdiv"
        >
           <div className="alert-info"
           style={{
            display : 'flex',
            justifyContent: 'center',
            alignItems:'center'
           }}>
            <img src={alertimg} alt="feedback-alert"></img>
            <div className='alert-text'>
            <h2
          style={{
            marginBottom:'0.9em',
            fontSize:'1.1em'
          }}>Thanks for the Feedback</h2>
          <p>We’ve received your response successfully</p>

            </div>
         
          </div>
         
          <button
          className="alertbtn"
          onClick={handleClose}
          >X</button>
        </div>)
        }
            
        
        </>
    )
}
import { useEffect, useState } from "react";

function Cookies(params) {
    const[visibility, setVisibility] = useState('')
    const[preference, setPreference] = useState(true)
    const[statistics, setStatistics] = useState(true)
    const[marketing, setMarketing] = useState(true)
    function acceptCookies() {
        setVisibility('hide')
        storeCookies()
    }

    function storeCookies() {
      localStorage.setItem('cookies',true)
    }

    useEffect(()=>{
      const status = localStorage.getItem('cookies')
      if (status) {
        setVisibility('hide')
      }
    })
    return(
        <div className={`cookies ${visibility}`}>
        <div className="col-lef">
          <div className="detail">
            We use cookies to personalise content and ads, to provide social
            media features and to analyse our traffic, you consent to our
            cookies if you continue to use our website, for more details read
            our cookie policy.
          </div>
          <div className="buttons">
            <div className="li">
              <span>Necessary</span>
              <input
                type="checkbox"
                name="necessary[]"
                id="necessary[]"
                value="necessary"
                checked={true}
              />
            </div>
            <div className="li">
              <span>Preferences</span>
              <input
                onClick={()=>{setPreference((prev)=>!prev)}}
                type="checkbox"
                name="necessary[]"
                id="necessary[]"
                value="preferences"
                checked={preference}
              />
            </div>
            <div className="li">
              <span>Statistics</span>
              <input
                onClick={()=>{setStatistics((prev)=>!prev)}}
                type="checkbox"
                name="necessary[]"
                id="necessary[]"
                value="statistics"
                checked={statistics}
              />
            </div>
            <div className="li">
              <span>Marketing</span>
              <input
                onClick={()=>{setMarketing((prev)=>!prev)}}
                type="checkbox"
                name="necessary[]"
                id="necessary[]"
                value="marketing"
                checked={marketing}
              />
            </div>
          </div>
        </div>
        <div className="col-ryt" onClick={acceptCookies}>
          <a>Accept</a>
        </div>
      </div>
    )
}

export default Cookies;
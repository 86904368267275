
import HomePage from "./pages/homepage/HomePage";
import "./App.css";


function App() {
  return (
    <>
      <div>
        <div>
          <div className="main">
            <HomePage/>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
